
:root {
  --wlm-size-1_5: 0.35rem;
  --wlm-size-4_5: 1.3rem;
  --wlm-size-12_5: 12rem;

  --wlm-control-border-radius: var(--wlm-size-1_5);
  --wlm-section-border-radius: var(--radius-3);

  --wlm-grid-column-spacing: var(--size-5);
  --wlm-grid-row-spacing: var(--size-7);
  --wlm-section-spacing: var(--size-9);
  --wlm-prose-spacing: var(--wlm-size-4_5);

  --wlm-max-prose-inline-size: 60rem;
  --wlm-prose-color: var(--gray-9);
  --wlm-prose-font-weight: 425;
  --wlm-prose-line-height: 1.5;

  --wlm-heading-1-font-size: var(--font-size-fluid-3);
  --wlm-heading-2-font-size: var(--font-size-fluid-2);
  --wlm-heading-3-font-size: 1.6em;
  --wlm-heading-4-font-size: 1.3em;

  --wlm-heading-color: var(--teal-11);

  --wlm-body-background: rgb(248, 246, 242);
  --wlm-body-font-size: 120%;

  --wlm-section-background: white;

  --wlm-action-color: var(--orange-9);
  --wlm-action-color-hover: var(--orange-8);
  --wlm-action-color-faded: var(--orange-2);
  --wlm-prose-link-underline-offset: 0.125em;

  --wlm-surface-1: var(--gray-0);
  --wlm-surface-2: var(--gray-2);
  --wlm-surface-3: var(--gray-3);
  --wlm-surface-4: var(--gray-4);

  /** hmmm */
  @media (--sm-down) {
    --wlm-body-font-size: 115%;
  }
}

:where(a) {
  color: var(--wlm-action-color);
  text-decoration: none;
}

:where(a:hover) {
  color: var(--wlm-action-color-hover);
}

:where(label) {
  font-size: 0.9em;
  font-weight: bold;
}

:where(input) {
  background: white;
  color: inherit;
  padding-inline: calc(var(--size-2) * 1.5);
  padding-block: var(--size-2);
  font-size: 0.9em;
  font-family: inherit;
  border: 1px solid var(--wlm-action-color-faded);
  border-radius: var(--wlm-control-border-radius);
}

:where(input:hover) {
  border-color: var(--wlm-action-color-hover);
}

:where(button) {
  cursor: pointer;
  background: var(--wlm-action-color);
  color: white;
  padding-inline: calc(var(--size-2) * 1.5);
  padding-block: var(--size-2);
  font-size: 0.9em;
  font-family: inherit;
  font-weight: bold;
  border: 0;
  border-radius: var(--wlm-control-border-radius);
}

:where(button:hover) {
  background: var(--wlm-action-color-hover);
}

[wlm-prose] :where(a) {
  text-decoration: underline;
  text-decoration-color: var(--wlm-action-color-faded);
  text-underline-offset: var(--wlm-prose-link-underline-offset);
}

[wlm-prose] :where(p) {
  margin-block: var(--wlm-prose-spacing);
}

[wlm-prose] :where(h1,h2,h3,h4,h5,h6) {
  color: var(--wlm-heading-color);
}
